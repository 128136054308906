import React, { useState } from "react";
import "./App.css";
import { ConfigProvider, Button, Layout, Drawer } from "antd";
import { Checkbox, Form, Input } from "antd";

const { Header, Content, Footer } = Layout;

function App() {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<"" | "error">("");
  const [form] = Form.useForm<{ username: string; password: string }>();

  const onFinish = (values: any) => {
    setErrorMessage("error");
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ConfigProvider>
      <Layout>
        <Header className="header">
          <Button type="primary" onClick={showDrawer}>
            Login
          </Button>
        </Header>
        <Content className="content">
          <Drawer title="Login" placement="right" onClose={onClose} open={open}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                validateStatus={errorMessage}
                help={errorMessage ? "Wrong user/password compilation!" : ""}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                  },
                ]}
                validateStatus={errorMessage}
                help={errorMessage ? "Wrong user/password compilation!" : ""}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 16 }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
          <ul className="orbit-wrap">
            <li className="orbit-center">
              <img src="/logo.png" className="orbit-logo" alt="logo" />
            </li>

            <li>
              <ul className="ring-0">
                <li>
                  <i className="orbit-icon fa fa-test0"></i>
                </li>
                <li>
                  <i className="orbit-icon fa fa-test1"></i>
                </li>
              </ul>
            </li>

            <li>
              <ul className="ring-1">
                <li>
                  <i className="orbit-icon fa fa-test0"></i>
                </li>
                <li>
                  <i className="orbit-icon fa fa-test2"></i>
                </li>
              </ul>
            </li>
            <li>
              <ul className="ring-2">
                <li>
                  <i className="orbit-icon fa fa-test1"></i>
                </li>
                <li>
                  <i className="orbit-icon fa fa-test2"></i>
                </li>
              </ul>
            </li>
          </ul>
        </Content>
        <Footer>
          <p>
            <b>Osmium Capital</b>
            <br />
            Email : info@osmiumcap.com
            <br />
            <span className="footer-copyright">
              Copyright © Osmium Capital, All Rights Reserved.
            </span>
          </p>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
